import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgStyle } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ActivatedRoute } from '@angular/router'
import { TranslocoService } from '@ngneat/transloco'
import { Subscription } from 'rxjs'

import { MessageService } from '~core/services/message.service'
import { ConnectableChannel } from '~features/integrations/models/connectable-channel.model'
import { Channel } from '~features/projects/models/channel.model'
import { IconOutlined } from '~icons'

import { ShortNumberPipe } from '../../../pipes/short-number.pipe'
import { AvatarComponent } from '../../../ui/avatar/avatar.component'
import { ButtonComponent } from '../../../ui/button/button.component'
import { TextComponent } from '../../../ui/text/text.component'
import { TooltipComponent } from '../../../ui/tooltip/tooltip.component'
import { ChannelTypeLogoComponent } from '../../channel-type-logo/channel-type-logo.component'

@Component({
  selector: 'sb-channel-card',
  template: `
    <div
      (click)="onAdd()"
      [ngClass]="{ pointer: clickable && available }"
      class="channel-card"
      [ngStyle]="{ backgroundColor: transparent && 'transparent' }"
    >
      <div class="mb-2 flex items-center p-2">
        @if (checkable) {
          <input (change)="onCheckboxChange($event)" [value]="channel._id" class="mr-3" type="checkbox" />
        }
        <div class="col-auto">
          @if (!available) {
            <div class="unavailable-overlay"></div>
          }
          <sb-avatar [src]="channelPicture" [title]="channel.name" size="sm">
            <sb-channel-type-logo [type]="channel.type" />
          </sb-avatar>
        </div>
        <div class="grow overflow-hidden px-2">
          @if (!available) {
            <div class="unavailable-overlay"></div>
          }
          <sb-text variant="bodyMedium" noMargin truncate>{{ channel.name }}</sb-text>
          <!-- Category -->
          <sb-text variant="label" noMargin>
            @switch (channel.type) {
              @case ('facebook') {
                {{ channel.category }}
              }
              @case ('instagram') {
                &#64;{{ channel.username }}
              }
            }
          </sb-text>
        </div>
        @if (peopleCount !== undefined) {
          <div class="badge badge-secondary items-center gap-1">
            <sb-tooltip [content]="peopleCount > 1000 ? peopleCount.toString() : null" position="left">
              {{ peopleCount | shortNumber }}
            </sb-tooltip>
            <mat-icon [svgIcon]="Icon.People" [ngStyle]="{ width: '16px', height: '16px' }" />
          </div>
        }
        @if (removable) {
          <div>
            <sb-button variant="icon" [icon]="Icon.Delete" (sbClick)="onRemove()" />
          </div>
        }
        @if (!available && showLock) {
          <div [matTooltip]="channelStrings.unavailable" class="refresh-section">
            <mat-icon [svgIcon]="Icon.Lock" />
          </div>
        }
        @if (available && clickable) {
          <div class="col-auto mx-2">
            <!--      <sb-button variant="text">{{ channelStrings.connect }}</sb-button>-->
          </div>
        }
      </div>
    </div>
  `,
  styleUrls: ['./channel-card.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    AvatarComponent,
    ChannelTypeLogoComponent,
    TextComponent,
    TooltipComponent,
    MatIconModule,
    ButtonComponent,
    MatTooltipModule,
    ShortNumberPipe,
  ],
})
export class ChannelCardComponent implements OnInit, OnDestroy {
  protected Icon = IconOutlined
  @Output() add = new EventEmitter<Channel | ConnectableChannel>()
  @Input() available = true
  @Input() channel: Channel | ConnectableChannel | any
  channelStrings: any
  @Input() checkable = false
  @Output() checkboxChange = new EventEmitter<Event>()
  @Input() clickable = false
  @Input() peopleCount: number
  @Input() removable = true
  @Output() remove = new EventEmitter<Channel | ConnectableChannel>()
  @Input() showLock = true
  private subscription = Subscription.EMPTY

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private translateService: TranslocoService,
  ) {}

  private _transparent = false

  @Input()
  get transparent(): boolean {
    return this._transparent
  }

  set transparent(value: BooleanInput) {
    this._transparent = coerceBooleanProperty(value)
  }

  get channelPicture(): string {
    const channel = this.channel as Channel
    switch (channel.type) {
      case 'linkedin-organization':
        return channel.picture_url
      default:
        return channel.picture
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit(): void {
    this.subscription = this.translateService
      .selectTranslateObject('projects.channels', {
        'removeModal.content': {
          channelName: this.channel.name,
        },
      })
      .subscribe((channelStrings) => {
        this.channelStrings = channelStrings
      })
  }

  onAdd(): void {
    if (!this.clickable || !this.available) {
      return
    }

    this.add.emit(this.channel)
  }

  onCheckboxChange($event: Event): void {
    this.checkboxChange.emit($event)
  }

  onRemove(): void {
    const title = this.channelStrings.removeModal.title
    const content = this.channelStrings.removeModal.content
    this.messageService.confirm(title, content).subscribe((confirmed) => {
      if (confirmed) {
        this.remove.emit(this.channel)
      }
    })
  }
}
